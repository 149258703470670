@use 'styles/colors';

.wrapper {
    width: auto;

    &.error {
        border-left: 2px solid colors.$secondary-dark;
        padding-left: 1em;
        small {
            font-size: 1em;
            font-weight: 700;
        }
        input {
            border: 2px solid colors.$secondary-dark;
            &:focus {
                outline: 2px solid colors.$secondary-dark;
            }
        }
    }
}
