@use 'styles/colors';
@use 'styles/components';

.entry {
    display: flex;
    width: 100%;

    label {
        margin: 0;
    }

    input,
    select {
        box-sizing: border-box;
        margin: 0;
    }

    &.compact {
        @extend %compact;

        label {
            @extend %compact;
        }

        input,
        select {
            @extend %compact-input;
        }
    }

    &.vertical {
        flex-direction: column;
        gap: 0.5rem;
    }

    &.error {
        border-left: 2px solid colors.$secondary-dark;
        padding-left: 1rem;

        input {
            outline: none;
        }
    }
}
