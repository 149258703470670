.navigation {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    h2 {
        font-size: 1.375rem;
    }

    nav {
        min-width: 12.5rem;
        ol {
            list-style: none;
            padding-left: 0;
        }

        li {
            position: relative;
            border-top: 1px solid #e6e6e6;
            display: flex;
            align-items: center;
            padding: 0.75rem 1rem;
            cursor: pointer;

            a {
                flex-grow: 1;
                padding: 0;
                font-weight: 400;
                font-size: 1rem;
                color: #5c5c5c;

                &:visited {
                    color: #5c5c5c;
                }

                &:focus {
                    outline: 0.25rem solid #2491ff;
                }
                &:hover {
                    text-decoration: none;
                }
            }
            &:hover {
                a {
                    color: #005ea2;
                    font-weight: 700;
                }
            }

            &.active {
                a {
                    font-weight: 700;
                    color: #005ea2;

                    &:visited {
                        color: #005ea2;
                    }
                }

                &::before {
                    background-color: #005ea2;
                    border-radius: 99rem;
                    content: '';
                    position: absolute;
                    bottom: 0.1rem;
                    top: 0.1rem;
                    width: 0.25rem;
                    height: 2.4375rem;
                    left: 0;
                }
            }

            &:last-of-type {
                border-bottom: 1px solid #e6e6e6;
            }
        }
    }
}
