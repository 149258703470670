@use 'styles/colors';
@use 'styles/borders';

.addPatientForm {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-right: 1rem;

    .formCard {
        background-color: colors.$base-white;

        @extend %thin;
        @include borders.rounded();
    }

    input {
        background-color: colors.$base-white;
    }

    .formContent {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 1rem;
    }
}
