@use 'styles/colors';

.page-builder {
    padding: 1rem;
    display: flex;
    gap: 1rem;

    .page-builder__content {
        width: 100%;
    }
}
